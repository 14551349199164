class SingleOpenAccordion extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.detailsElements = this.querySelectorAll('details');
    // Add toggle event listeners to each details element
    this.detailsElements.forEach(details => {
      details.addEventListener('toggle', (event) => this.handleToggle(event));
    });
    console.log(this.detailsElements)
  }

  handleToggle(event) {
    console.log('toggle')
    if (event.target.open) {
      this.detailsElements.forEach(details => {
        if (details !== event.target) {
          details.open = false;
        }
      });
    }
  }
}

customElements.define('accordion-group', SingleOpenAccordion);